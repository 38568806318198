import { addDays, differenceInDays, isSameDay, parseISO } from "date-fns";

import { Event, EventDay } from "@/salesTypes/events";
import { Customer } from "@/types/Customer";
import { Subscription } from "@/salesTypes/subscriptions";
import { roles } from "@/salesTypes/salesPersons";

import { fullRoles } from "./consts";

export function fromSentence(sentence: string, takeNone = false) {
	if (Array.isArray(sentence)) {
		return sentence;
	}
	if (takeNone && sentence === "none") {
		return [];
	} else if (sentence) {
		let commaList = sentence.replace(" and ", ", ");
		commaList = commaList.replace(/, /g, ",");
		return commaList.split(",");
	} else {
		return [];
	}
}

export function toSentence(arr: Array<string>, addNone = false) {
	if (!Array.isArray(arr)) {
		arr = fromSentence(arr);
	}
	if (addNone) {
		arr = arr.filter(function (item) {
			return item !== "none";
		});
	}
	let value =
		arr.slice(0, -2).join(", ") +
		(arr.slice(0, -2).length ? ", " : "") +
		arr.slice(-2).join(" and ");

	if (addNone) {
		if (!value) {
			value = "none";
		}
	}
	return value;
}

export const dateRange = (startDate: Date, endDate: Date) => {
	const days = differenceInDays(endDate, startDate);

	return [...Array(days + 1).keys()].map((i) => addDays(startDate, i));
};

export const permissionLevel = (role?: typeof roles[number]) => {
	return fullRoles.find((theRole) => theRole.role === role)?.level || 0;
};

export function getCookie(name: string) {
	const regex = new RegExp(`(^| )${name}=([^;]+)`);
	const match = document.cookie.match(regex);
	if (match) {
		return match[2];
	} else {
		return false;
	}
}
